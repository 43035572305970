import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { forEach, omit, orderBy } from "lodash";
import SEO from "@components/seo";
import ProjectsGrid from "@components/ProjectsGrid";
import SliderHero from "@components/SliderHero";
import { getDetails } from "@components/utilities";

// const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

const ProjectsPage = ({ data }) => {
  const [projects, setProjects] = useState(null);
  const { allDatoCmsProject } = data;

  // REFACTOR
  useEffect(() => {
    let p = [];
    let sorted = [];
    if (allDatoCmsProject.edges) {
      forEach(allDatoCmsProject.edges, edge => {
        let project = omit(edge.node, ["__typename"]);
        const { details, categories, location } = getDetails({ project });

        project.details = details;
        project.categories = categories;
        project.location = location;

        if (edge.node.displayOnWebsite === true) {
          p.push(project);
        }
        const lastFour = project.leistungszeitraum.slice(-4);
        const areDigits = /^\d{4}$/.test(lastFour);
        project.finishDate = areDigits ? Number(lastFour) : 3000;

        sorted = orderBy(p, ["finishDate"], ["desc"]);
      });
      setProjects(sorted);
    }
  }, []);

  const slogan = data.datoCmsProjektseite.slogan;
  const seoTitle = `Hermann & Valentiny und Partner Architekten | Projekte`;
  const seoDescription = `Hermann & Valentiny und Partner Architekten | Projekte | ${slogan}`;
  const featuredImages = data.datoCmsProjektseite?.featuredImages;
  const seoImage = featuredImages ? featuredImages[0].fluid.src : null;

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <SliderHero
        items={featuredImages}
        pageTitle="Projekte"
        pageSlogan={slogan}
      />
      {projects && <ProjectsGrid projects={projects} />}
    </React.Fragment>
  );
};

export default ProjectsPage;

export const query = graphql`
  query ProjectQuery {
    datoCmsProjektseite {
      slogan
      featuredImages {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    allDatoCmsProject(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          __typename
          id
          name
          position
          slug
          displayOnWebsite
          description
          featuredImage {
            fluid(imgixParams: { w: "600", q: 60 }) {
              src
              srcSet
              aspectRatio
            }
          }
          gallery {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
          buroGewerbe
          wohnen
          kultur
          lernen
          gesundheit
          stadtebau
          projectStatus
          projectPartners
          leistungen
          leistungszeitraum
          clientName
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
