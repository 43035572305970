import React, { useRef } from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { Div } from "./Markup";
import { Text, Title } from "./Typography";

const ProjectThumbnail = ({ fluid, className, name, slug, categories }) => {
  const abbreviatedTitle = name.substr(0, name.indexOf(" "));
  const restOfTheTitle = name.substr(name.indexOf(" | ") + 2);
  const itemRef = useRef(null);
  return (
    <div
      ref={itemRef}
      className={className}
      css={css`
        min-width: 100%;
        position: relative;
        pointer-events: all;
      `}
    >
      <Link to={`/projekte/${slug}`}>
        <Div
          flex
          jc="flex-start"
          ai="center"
          mb="xs"
          css={css`
            width: 100%;
            h2 {
              margin-right: 16px;
            }
            @media (min-width: 768px) {
              display: none;
            }
          `}
        >
          <Title tag="h2" size="sm">
            {abbreviatedTitle}
          </Title>
          {categories.map((category, index) => (
            <Text key={`category--${index}`} textStyle="label">
              {category && category.title}
            </Text>
          ))}
        </Div>
        <Title
          tag="h2"
          size="sm"
          className="name"
          css={css`
            @media (max-width: 767px) {
              display: none;
            }
          `}
        >
          {abbreviatedTitle}
        </Title>
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            padding-top: 56.25%;
            overflow: hidden;
            padding-left: 16px;
            padding-right: 16px;

            &:hover {
              cursor: pointer;
              .background-image {
                transform: translate3d(-50%, -50%, 0) scale(1);
              }
              .overlay {
                opacity: 1;
                transition-delay: 0s;
              }
              .overlay-content {
                margin-top: 0px;
                opacity: 1;
                transition-delay: 0.15s;
              }
            }
          `}
        >
          <div
            className="background-image"
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              pointer-events: none;
              background-image: url("${fluid && fluid.src}");
              height: 100%;
              background-size: cover;
              background-position: center center;
              width: 100%;
              transition: 0.25s all;
              transform: translate3d(-50%, -50%, 0) scale(1.05);
            `}
          />
          <div
            className="overlay"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              pointer-events: none;
              background: rgba(0, 0, 0, 0.55);
              opacity: 0;
              transition: 0.25s all;
              transition-delay: 0.15s;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <div
              className="overlay-content"
              css={css`
                color: white;
                margin-top: 9px;
                opacity: 0;
                transition: 0.15s all;
                transition-delay: 0s;
                max-width: 300px;
                text-align: center;
              `}
            >
              <Title tag="h2" size="sm" mb="xs">
                {restOfTheTitle}
              </Title>
              {categories.map((category, index) => {
                return (
                  <Text
                    key={`category--2--${index}`}
                    textStyle="label"
                    id={category && category.id}
                  >
                    {category && category.title}
                  </Text>
                );
              })}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectThumbnail;
