import { css } from "@emotion/react";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Div, Container } from "./Markup";
import { find } from "lodash";
import ProjectThumbnail from "./ProjectThumbnail";
import { useLocation } from "@reach/router";
import { CATEGORIES } from "./utilities";
import { Filter } from "./ProjectFilter";
import queryString from "query-string";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { RED, WHITE } from "../styles/colors";

const options = [
  { label: "neu-alt", value: "original-order" },
  { label: "a-z", value: "name" },
];

const getProjectsWithFilters = projects => {
  return projects
    ? projects.map(project => {
        return {
          ...project,
          id: project.id,
          filter: project.categories.map(category =>
            category ? category.id : null
          ),
        };
      })
    : [];
};

const ProjectsGrid = ({ projects }) => {
  const location = useLocation();
  const params = useRef(new URLSearchParams("?"));
  const containerRef = useRef(null);
  const gridRef = useRef(null);

  const categoriesWithFilters = [
    { label: "all", title: "Alle", isChecked: true },
    ...CATEGORIES.map(category => ({
      label: category && category.id,
      title: category && category.title,
      isChecked: false,
    })),
  ];

  const [filters, setFilters] = useState(categoriesWithFilters);

  const projectsWithFilters = getProjectsWithFilters(projects);

  useEffect(() => {
    const Isotope = require("isotope-layout");
    if (!gridRef.current && document.querySelector(".project")) {
      gridRef.current = new Isotope(containerRef.current, {
        itemSelector: ".project",
        layoutMode: "fitRows",
        getSortData: {
          name: ".name",
        },
      });
    }

    if (location.search) {
      params.current = new URLSearchParams(location.search);
      const query = queryString.parse(location.search);

      if (query.category) {
        gridRef.current.arrange({
          filter: `.${query.category}`,
        });

        let catsWithFilters = [
          { label: "all", title: "Alle", isChecked: false },
          ...CATEGORIES.map(category => ({
            label: category?.id,
            title: category?.title,
            isChecked: query.category === category.id,
          })),
        ];

        setFilters([...catsWithFilters]);
      }
    }
  }, []);

  const updateParamsAndQuery = ({ id }) => {
    let url = null;
    let pageTitle = null;

    if (id && id !== "all") {
      params.current.set("category", id);
      url = `${location.pathname}?category=${id}`;
      pageTitle = `Projekte: ${find(CATEGORIES, { id: id }).title}`;
    } else {
      params.current.set("category", null);
      url = `${location.pathname}`;
      pageTitle = `Projekte`;
    }

    document.title = pageTitle;
    window.history.replaceState({}, pageTitle, url);
  };

  return (
    <Container>
      <Div
        flex
        mb="md"
        css={css`
          flex-wrap: wrap;
          margin: 0px -5px;
          margin-bottom: 32px;

          @media (min-width: 768px) {
            justify-content: space-between;
            align-items: center;
          }

          .dropdown {
            width: 140px;
            padding-right: 10px;

            .Dropdown-control {
              border: none;
              border: 2px solid ${WHITE};
              color: ${WHITE};
              background-color: transparent;
              height: 64px;
              margin: 0px 4px 8px 0px;
              display: flex;
              align-items: center;
              padding: 8px 32px 8px 10px;

              @media (max-width: 767px) {
                height: 48px;
              }
            }

            .Dropdown-arrow {
              border-color: ${WHITE} transparent transparent;
              top: calc(50% - 2px);
            }

            .Dropdown-placeholder,
            .Dropdown-menu {
              font-family: "lucida-sans";
              font-size: 0.5rem;
              line-height: 1.35em;
              font-weight: normal;
              text-transform: uppercase;
              background-color: ${RED};
              color: ${WHITE};

              @media (min-width: 992px) {
                font-size: 0.75rem;
              }

              @media (min-width: 1200px) {
                font-size: 0.85rem;
              }

              .Dropdown-option {
                background-color: transparent;
                color: white;
                position: relative;
                border-bottom: 1px solid ${WHITE};
              }
            }

            &.is-open {
              .Dropdown-arrow {
                border-color: transparent transparent ${WHITE};
              }
            }
          }
        `}
      >
        <Div
          flex
          css={css`
            flex-wrap: wrap;
          `}
        >
          {filters.map((f, idx) => {
            return (
              <Filter
                key={`f-${idx}`}
                id={f.label}
                isChecked={f.isChecked}
                onClick={() => {
                  gridRef.current.arrange({
                    filter: f.label === "all" ? "*" : `.${f.label}`,
                  });
                  const _filters = [
                    {
                      label: "all",
                      title: "Alle",
                      isChecked: f.label === "all",
                    },
                    ...CATEGORIES.map(category => ({
                      label: category.id,
                      title: category.title,
                      isChecked: category.id === f.label,
                    })),
                  ];
                  setFilters([..._filters]);
                  updateParamsAndQuery({ id: f.label });
                }}
              >
                <label htmlFor={f.label}>{f.title}</label>
              </Filter>
            );
          })}
        </Div>
        <Div
          flex
          ai="center"
          css={css`
            @media (max-width: 767px) {
              margin-top: 16px;
            }
          `}
        >
          <Dropdown
            className="dropdown"
            options={options}
            onChange={e => {
              gridRef.current.arrange({ sortBy: e.value });
            }}
            value={"original-order"}
            placeholder="Select an option"
          />
        </Div>
      </Div>
      <div
        ref={containerRef}
        css={css`
          min-height: 600px;
          margin-left: -10px;

          &:after {
            content: "";
            display: block;
            clear: both;
          }
        `}
      >
        {projectsWithFilters.map((project, idx) => {
          return (
            <div
              key={`p-${idx}`}
              className={`project ${project.filter[0]}`}
              data-filter={`${project.filter[0]}`}
              data-category={`${project.filter[0]}`}
              css={css`
                position: relative;
                float: left;
                padding: 0px 5px;
                width: 100%;
                margin-bottom: 30px;

                @media (min-width: 768px) {
                  width: 33.33%;
                  margin-bottom: 30px;
                }
              `}
            >
              <ProjectThumbnail
                name={project.name}
                slug={project.slug}
                categories={project.categories}
                fluid={project.featuredImage && project.featuredImage.fluid}
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default ProjectsGrid;
